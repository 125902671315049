import Home from "../assets/icons/NavIcons/home.svg";
import HoverHome from "../assets/icons/NavIcons/hoverhome.svg";
import Curriculum from "../assets/icons/NavIcons/curriculum.svg";
import HoverCurriculum from "../assets/icons/NavIcons/hovercurriculum.svg";
import Favourites from "../assets/icons/NavIcons/favourite.svg";
import HoverFavourites from "../assets/icons/NavIcons/hoverfav.svg";
import Givings from "../assets/icons/NavIcons/givings.svg";
import HoverGivings from "../assets/icons/NavIcons/hovergivings.svg";
import Testimonials from "../assets/icons/NavIcons/testimonials.svg";
import HoverTestimonials from "../assets/icons/NavIcons/hovertestimonials.svg";

import Logo from "../assets/icons/logo.png";

import ImgSmall1 from "../assets/images/smallCarousel/ImgSmall1.png";
import ImgSmall2 from "../assets/images/smallCarousel/ImgSmall2.png";
import ImgSmall3 from "../assets/images/smallCarousel/ImgSmall3.png";
import ImgSmall4 from "../assets/images/smallCarousel/ImgSmall4.png";
import ImgSmall5 from "../assets/images/smallCarousel/ImgSmall5.png";
import ImgSmall6 from "../assets/images/smallCarousel/ImgSmall6.png";
import ImgSmall7 from "../assets/images/smallCarousel/ImgSmall7.png";

import Promise1 from "../assets/images/promise/Promise1.png";
import Promise2 from "../assets/images/promise/Promise2.png";
import Promise3 from "../assets/images/promise/Promise3.png";
import Promise4 from "../assets/images/promise/Promise4.png";
import Promise5 from "../assets/images/promise/Promise5.png";

import SubVideo1 from "../assets/images/video/SubVideo1.png";
import SubVideo2 from "../assets/images/video/SubVideo2.png";
import SubVideo3 from "../assets/images/video/SubVideo3.png";
import SubVideo4 from "../assets/images/video/SubVideo4.png";
import SubVideo5 from "../assets/images/video/SubVideo5.png";

import Fav11 from "../assets/images/Fav1/i1.png";
import Fav12 from "../assets/images/Fav1/i2.png";
import Fav13 from "../assets/images/Fav1/i3.png";
import Fav14 from "../assets/images/Fav1/i4.png";
import Fav15 from "../assets/images/Fav1/i5.png";
import Fav16 from "../assets/images/Fav1/i6.png";

import Fav21 from "../assets/images/Fav2/i1.png";
import Fav22 from "../assets/images/Fav2/i2.png";
import Fav23 from "../assets/images/Fav2/i3.png";
import Fav24 from "../assets/images/Fav2/i4.png";
import Fav25 from "../assets/images/Fav2/i5.png";

import Fav31 from "../assets/images/Fav3/i1.png";
import Fav32 from "../assets/images/Fav3/i2.png";
import Fav33 from "../assets/images/Fav3/i3.png";
import Fav34 from "../assets/images/Fav3/i4.png";
import Fav35 from "../assets/images/Fav3/i5.png";
import Fav36 from "../assets/images/Fav3/i6.png";
import Fav37 from "../assets/images/Fav3/i7.png";

// import Fav41 from "../assets/images/Fav4/i1.png";
// import Fav42 from "../assets/images/Fav4/i2.png";
// import Fav43 from "../assets/images/Fav4/i3.png";
// import Fav44 from "../assets/images/Fav4/i4.png";
// import Fav45 from "../assets/images/Fav4/i5.png";

import Fav51 from "../assets/images/Test1/i1.png";
import Fav52 from "../assets/images/Test1/i2.png";
import Fav53 from "../assets/images/Test1/i3.png";
import Fav54 from "../assets/images/Test1/i4.png";
import Fav55 from "../assets/images/Test1/i5.png";
import Fav56 from "../assets/images/Test1/i6.png";

import Fav61 from "../assets/images/Test2/i1.png";
import Fav62 from "../assets/images/Test2/i2.png";
import Fav63 from "../assets/images/Test2/i3.png";
import Fav64 from "../assets/images/Test2/i4.png";
import Fav65 from "../assets/images/Test2/i5.png";
import Fav66 from "../assets/images/Test2/i6.png";
import Fav67 from "../assets/images/Test2/i7.png";

import Rectangle1 from "../assets/images/Rectangle1.png";
import Rectangle2 from "../assets/images/Rectangle2.png";
import Rectangle3 from "../assets/images/Rectangle3.png";
import Rectangle4 from "../assets/images/Rectangle4.png";
import Rectangle5 from "../assets/images/Rectangle5.png";

import Luke1 from "../assets/images/luke/luke1.png";
import Luke2 from "../assets/images/luke/luke2.png";
import Luke3 from "../assets/images/luke/luke3.png";
import Luke4 from "../assets/images/luke/luke4.png";
import Luke5 from "../assets/images/luke/luke5.png";

import AwardImg from "../assets/images/AwardImg.png";

export const navIconArray = [
  {
    icon: Home,
    hover: HoverHome,
    route: "/home",
    title: "Home",
    key: "home_lbl",
  },
  {
    icon: Curriculum,
    hover: HoverCurriculum,
    route: "/curriculum",
    title: "Curriculum",
    key: "curriculum_lbl",
  },
  {
    icon: Favourites,
    hover: HoverFavourites,
    route: "/favourites",
    title: "My Favourites",
    key: "my_favourites_lbl",
  },
  {
    icon: Givings,
    hover: HoverGivings,
    route: "/givings",
    title: "Givings",
    key: "give_lbl",
  },
  {
    icon: Testimonials,
    hover: HoverTestimonials,
    route: "/testimonials",
    title: "Testimonials",
    key: "testimonials_lbl",
  },
];

export const logo = Logo;

export const ArrayOfImgs = [
  {
    image: ImgSmall1,
    title: "Mathew",
  },
  {
    image: ImgSmall2,
    title: "Luke",
  },
  {
    image: ImgSmall3,
    title: "John",
  },
  {
    image: ImgSmall4,
    title: "Roger Nathal",
  },
  {
    image: ImgSmall5,
    title: "John Gerava",
  },
  {
    image: ImgSmall6,
    title: "Brain Lara",
  },
  {
    image: ImgSmall1,
    title: "Mathew",
  },
  {
    image: ImgSmall1,
    title: "Mathew",
  },
  {
    image: ImgSmall2,
    title: "Luke",
  },
  {
    image: ImgSmall3,
    title: "John",
  },
  {
    image: ImgSmall4,
    title: "Roger Nathal",
  },
  {
    image: ImgSmall5,
    title: "John Gerava",
  },
  {
    image: ImgSmall6,
    title: "Brain Lara",
  },
  {
    image: ImgSmall1,
    title: "Mathew",
  },
  {
    image: ImgSmall2,
    title: "Luke",
  },
  {
    image: ImgSmall3,
    title: "John",
  },
  {
    image: ImgSmall4,
    title: "Roger Nathal",
  },
  {
    image: ImgSmall5,
    title: "John Gerava",
  },
  {
    image: ImgSmall6,
    title: "Brain Lara",
  },
  // {
  //     image: ImgSmall2,
  //     title: 'Luke'
  // },
  // {
  //     image: ImgSmall3,
  //     title: 'John'
  // },
];

export const ArrayOfImgs2 = [
  {
    image: Promise1,
    title: "",
  },
  {
    image: Promise2,
    title: "",
  },
  {
    image: Promise3,
    title: "",
  },
  {
    image: Promise4,
    title: "",
  },
  {
    image: Promise1,
    title: "",
  },
  {
    image: Promise1,
    title: "",
  },
  {
    image: Promise2,
    title: "",
  },
  {
    image: Promise3,
    title: "",
  },
  {
    image: Promise4,
    title: "",
  },
  {
    image: Promise1,
    title: "",
  },
  {
    image: Promise1,
    title: "",
  },
  {
    image: Promise2,
    title: "",
  },
  {
    image: Promise3,
    title: "",
  },
  // {
  //     image: Promise4,
  //     title: ''
  // },
  // {
  //     image: Promise1,
  //     title: ''
  // },
];

export const ArrayOfImgs3 = [
  {
    image: SubVideo1,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo2,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo3,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo4,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo5,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo1,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo2,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo3,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo4,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo5,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
];

export const ArrayOfImgs4 = [
  {
    image: ImgSmall5,
    title: "John Gerava",
  },
  {
    image: ImgSmall6,
    title: "Brain Lara",
  },
  {
    image: ImgSmall1,
    title: "Mathew",
  },
  {
    image: ImgSmall1,
    title: "Mathew",
  },
  {
    image: ImgSmall2,
    title: "Luke",
  },
  {
    image: ImgSmall3,
    title: "John",
  },
  {
    image: ImgSmall4,
    title: "Roger Nathal",
  },
  {
    image: ImgSmall5,
    title: "John Gerava",
  },
  {
    image: ImgSmall6,
    title: "Brain Lara",
  },
  {
    image: ImgSmall1,
    title: "Mathew",
  },
  {
    image: ImgSmall1,
    title: "Mathew",
  },
  {
    image: ImgSmall2,
    title: "Luke",
  },
  {
    image: ImgSmall3,
    title: "John",
  },
  {
    image: ImgSmall4,
    title: "Roger Nathal",
  },
];

export const ArrayOfImgs5 = [
  {
    image: Fav11,
    title: "Mathew",
  },
  {
    image: Fav12,
    title: "Luke",
  },
  {
    image: Fav13,
    title: "John",
  },
  {
    image: Fav14,
    title: "Roger Nathal",
  },
  {
    image: Fav15,
    title: "John Gerava",
  },
  {
    image: Fav13,
    title: "John",
  },
  {
    image: Fav11,
    title: "Mathew",
  },
  {
    image: Fav11,
    title: "Mathew",
  },
  {
    image: Fav12,
    title: "Luke",
  },
  {
    image: Fav13,
    title: "John",
  },
  {
    image: Fav14,
    title: "Roger Nathal",
  },
  {
    image: Fav15,
    title: "John Gerava",
  },
  {
    image: Fav16,
    title: "Brain Lara",
  },
  {
    image: Fav11,
    title: "Mathew",
  },
];

export const ArrayOfImgs6 = [
  {
    image: Fav21,
    title: "Mathew",
  },
  {
    image: Fav22,
    title: "Luke",
  },
  {
    image: Fav23,
    title: "John",
  },
  {
    image: Fav24,
    title: "Roger Nathal",
  },
  {
    image: Fav21,
    title: "John Gerava",
  },
  {
    image: Fav21,
    title: "Mathew",
  },
  {
    image: Fav22,
    title: "Luke",
  },
  {
    image: Fav23,
    title: "John",
  },
  {
    image: Fav24,
    title: "Roger Nathal",
  },
  {
    image: Fav21,
    title: "John Gerava",
  },
];

export const ArrayOfImgs7 = [
  {
    image: Fav31,
    title: "Mathew",
  },
  {
    image: Fav32,
    title: "Luke",
  },
  {
    image: Fav33,
    title: "John",
  },
  {
    image: Fav34,
    title: "Roger Nathal",
  },
  {
    image: Fav35,
    title: "John Gerava",
  },
  {
    image: Fav36,
    title: "Roger Nathal",
  },
  {
    image: Fav31,
    title: "Mathew",
  },
  {
    image: Fav31,
    title: "Mathew",
  },
  {
    image: Fav32,
    title: "Luke",
  },
  {
    image: Fav33,
    title: "John",
  },
  {
    image: Fav34,
    title: "Roger Nathal",
  },
  {
    image: Fav35,
    title: "John Gerava",
  },
  {
    image: Fav36,
    title: "Roger Nathal",
  },
  {
    image: Fav31,
    title: "Mathew",
  },
];

// export const ArrayOfImgs8 = [
//     {
//         image: Fav41,
//         title: 'Mathew'
//     },
//     {
//         image: Fav42,
//         title: 'Luke'
//     },
//     {
//         image: Fav43,
//         title: 'John'
//     },
//     {
//         image: Fav44,
//         title: 'Roger Nathal'
//     },
//     {
//         image: Fav45,
//         title: 'John Gerava'
//     },
// ]

export const ArrayOfImgs9 = [
  {
    image: Fav56,
    title: "Roger Nathal",
  },
  {
    image: Fav52,
    title: "Luke",
  },
  {
    image: Fav53,
    title: "John",
  },
  {
    image: Fav54,
    title: "Roger Nathal",
  },
  {
    image: Fav55,
    title: "John Gerava",
  },
  {
    image: Fav56,
    title: "Roger Nathal",
  },
  {
    image: Fav54,
    title: "Roger Nathal",
  },
  {
    image: Fav52,
    title: "Luke",
  },
  {
    image: Fav53,
    title: "John",
  },
  {
    image: Fav54,
    title: "Roger Nathal",
  },
  {
    image: Fav55,
    title: "John Gerava",
  },
  {
    image: Fav56,
    title: "Roger Nathal",
  },
];

export const ArrayOfImgs10 = [
  {
    image: Fav61,
    title: "Mathew",
  },
  {
    image: Fav62,
    title: "Luke",
  },
  {
    image: Fav63,
    title: "John",
  },
  {
    image: Fav64,
    title: "Roger Nathal",
  },
  {
    image: Fav65,
    title: "John Gerava",
  },
  {
    image: Fav66,
    title: "Roger Nathal",
  },
  {
    image: Fav61,
    title: "John Gerava",
  },
  {
    image: Fav61,
    title: "Mathew",
  },
  {
    image: Fav62,
    title: "Luke",
  },
  {
    image: Fav63,
    title: "John",
  },
  {
    image: Fav64,
    title: "Roger Nathal",
  },
  {
    image: Fav65,
    title: "John Gerava",
  },
  {
    image: Fav66,
    title: "Roger Nathal",
  },
  {
    image: Fav61,
    title: "John Gerava",
  },
];

export const ArrayOfImgs11 = [
  {
    image: Rectangle1,
    title: "Lesson 1",
  },
  {
    image: Rectangle2,
    title: "Lesson 2",
  },
  {
    image: Rectangle3,
    title: "Lesson 3",
  },
  {
    image: Rectangle4,
    title: "Lesson 4",
  },
  {
    image: Rectangle1,
    title: "Lesson 5",
  },
  {
    image: Rectangle1,
    title: "Lesson 1",
  },
  {
    image: Rectangle2,
    title: "Lesson 2",
  },
  {
    image: Rectangle3,
    title: "Lesson 3",
  },
  {
    image: Rectangle4,
    title: "Lesson 4",
  },
  {
    image: Rectangle1,
    title: "Lesson 5",
  },
];

export const ArrayOfImgs12 = [
  {
    image: Luke1,
    title: "Lesson 1",
  },
  {
    image: Luke2,
    title: "Lesson 2",
  },
  {
    image: Luke3,
    title: "Lesson 3",
  },
  {
    image: Luke4,
    title: "Lesson 4",
  },
  {
    image: Luke1,
    title: "Lesson 5",
  },
];

export const ArrayOfImgs13 = [
  {
    image: SubVideo1,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo2,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo3,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo4,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo5,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo1,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo2,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo3,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo4,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo5,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo1,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo2,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo3,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo4,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo5,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo1,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo2,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo3,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo4,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: SubVideo5,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
];

export const ArrayOfImgs14 = [
  {
    image: AwardImg,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: AwardImg,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: AwardImg,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: AwardImg,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: AwardImg,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: AwardImg,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: AwardImg,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: AwardImg,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: AwardImg,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
  {
    image: AwardImg,
    title: "Book of John",
    subTitle: "Lorem ipsum is place holder where text commonly used in the graphic.",
  },
];

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    borderRadius: "16px",
    height: "95vh",
    aspectRatio: "10/16",
  },
};

export const navActiveCss = "bg-yellowish rounded-lg custom-stroke-black";
export const navHoverCss =
  "hover:bg-yellowish rounded-lg  custom-hover-stroke-black custom-stroke-grey";
