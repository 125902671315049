import { combineReducers } from "redux";
import GlobalLoaderReducer from "../GlobalLoader/GlobalLoaderReducer";
import languageReducer from "../Home/HomeReducer";
import commonReducer from "../Common/CommonReducer";

const rootReducer = combineReducers({
  globalLoaderReducer: GlobalLoaderReducer,
  languageReducer: languageReducer,
  common: commonReducer,
});

export default rootReducer;
