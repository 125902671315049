import { all } from "redux-saga/effects";
import LoginAndRegisterWatcherSaga from "../LoginAndRegister/LoginAndRegisterWatcherSaga";
import CategoriesWatcherSaga from "../Categories/CategoriesWatcherSaga";
import HomeWatcherSaga from "../Home/HomeWatcher";
import CurriculumWatcherSaga from "../Curriculam/CurriculumWatcher";
import TestimonialWatcherSaga from "../Testimonial/TestimonialWatcher";

export function* allAppRootSagas() {
  yield all([
    LoginAndRegisterWatcherSaga(),
    CategoriesWatcherSaga(),
    HomeWatcherSaga(),
    CurriculumWatcherSaga(),
    TestimonialWatcherSaga()
  ]);
}
