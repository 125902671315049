import * as actionTypes from "../ActionTypes";

export const actionUpdateGlobalLoaderSagaAction = (payload) => {
  return {
    type: actionTypes.APP_LOADING,
    payload: payload,
  };
};

export const setShowNavigationAlertAction = (payload) => {
  return {
    type: actionTypes.SHOW_NAVIGATION_ALERT,
    payload: payload,
  };
};
