import { useState } from "react";
import HeaderBar from "./HeaderBar";
import Navbar from "./Navbar";
import FooterBar from "./FooterBar";
import { useSelector } from "react-redux";

const PrivacyPolicy = () => {
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);
  const [tabValues, setTabsValues] = useState(["Privacy Policy"]);

  return (
    <div className="flex flex-col md:flex-row w-full overflow-hidden">
      <Navbar />
      <div className="md:ml-[104px] flex flex-col w-full min-h-screen">
        <HeaderBar
          back={true}
          tabValues={tabValues}
          active={0}
          getCurrentLanguageTitles={(titles) => {
            console.log("titles", titles);
            if (titles?.menus) {
              const requiredLangData = titles?.menus;
              setTabsValues([requiredLangData.privacy_policy_lbl || ""]);
            } else {
              setTabsValues(["Privacy Policy"]);
            }
          }}
        />

        <div className="flex flex-col pr-[1%] bg-black flex-1 overflow-y-auto w-full justify-between">
          <div className="w-full px-6 md:px-10 py-4 text-white">
            {currentLanguageTitles?.[0]?.home_meta?.privacy_policy_description ? (
              <div className="flex text-white w-full justify-center">
                <div
                  className="!text-white text-opacity-60 w-full md:w-1/2 !bg-none custom_privacy_policy"
                  dangerouslySetInnerHTML={{
                    __html: currentLanguageTitles?.[0]?.home_meta?.privacy_policy_description,
                  }}
                ></div>
              </div>
            ) : (
              <>
                <h1 className="text-white text-3xl font-semibold py-5 text-center">
                  Privacy Policy
                </h1>
                <div className="flex text-white w-full justify-center">
                  <div className="text-white text-opacity-60 w-full md:w-1/2">
                    <h2 className="font-semibold text-white text-lg">Introduction:</h2>
                    <p className="mb-4">
                      TodayWithGod ("we" or "us") is committed to protecting the privacy of our
                      users. This Privacy Policy outlines the types of personal information we
                      collect, how we use it, and the steps we take to safeguard it.
                    </p>
                    <h2 className="font-semibold text-white text-lg">Information We Collect:</h2>
                    <p className="mb-4">
                      <ul className="pl-5 list-disc">
                        <li>
                          Personal Information: When you create an account with TodayWithGod, we may
                          collect personal information such as your name, email address, and other
                          contact details.
                        </li>
                        <li>
                          Usage Data: We may collect information about how you interact with the
                          TodayWithGod app, including pages visited, time spent on each page, and
                          features accessed.
                        </li>
                        <li>
                          Payment Information: If you choose to support our mission by making a
                          financial contribution, we may collect payment information such as credit
                          card details. However, we do not store this information on our servers.
                          Instead, it is securely processed by our payment service provider.
                        </li>
                      </ul>
                    </p>
                    <h2 className="font-semibold text-white text-lg">
                      How We Use Your Information:
                    </h2>
                    <p className="mb-4">
                      <ul className="pl-5 list-disc">
                        <li>
                          To Provide Services: We use the information collected to provide you with
                          access to the features and resources offered by the TodayWithGod app.
                        </li>
                        <li>
                          To Improve User Experience: We may analyze usage data to understand how
                          users interact with the app and make improvements to enhance the user
                          experience.
                        </li>
                        <li>
                          To Communicate: We may use your contact information to send you important
                          updates, notifications, and newsletters related to the TodayWithGod app.
                        </li>
                      </ul>
                    </p>
                    <h2 className="font-semibold text-white text-lg">Sharing Your Information:</h2>
                    <p className="mb-4">
                      We do not sell, trade, or otherwise transfer your personal information to
                      third parties without your consent, except as described in this Privacy
                      Policy.
                    </p>
                    <h2 className="font-semibold text-white text-lg">Data Security:</h2>
                    <p className="mb-4">
                      We take reasonable measures to protect the personal information collected from
                      unauthorized access, disclosure, alteration, or destruction. However, no
                      method of transmission over the internet or electronic storage is completely
                      secure, and we cannot guarantee absolute security.
                    </p>
                    <h2 className="font-semibold text-white text-lg">
                      Changes to this Privacy Policy:
                    </h2>
                    <p className="mb-4">
                      We reserve the right to update or modify this Privacy Policy at any time. We
                      will notify you of any changes by posting the updated Privacy Policy on this
                      page. Your continued use of the TodayWithGod app constitutes your acceptance
                      of the revised Privacy Policy.
                    </p>
                    <h2 className="font-semibold text-white text-lg">Contact Us:</h2>
                    <p className="mb-4">
                      If you have any questions or concerns about this Privacy Policy, please
                      contact us at{" "}
                      <a
                        className="text-yellowish underline"
                        href="mailto:contact@todaywithGod.org"
                      >
                        contact@todaywithGod.org
                      </a>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
