import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { setLoginHardBlocker } from "../../store/Home/HomeAction";
import { useNavigate } from "react-router-dom";
import { logo } from "../../constants/NavbarConstants";

const customStyles = {
  overlay: {
    zIndex: 1000, // Adjust this value as needed
  },
  content: {
    zIndex: 1001, // Adjust this value as needed
  },
};

const LoginHardBlocker = ({ enableBack = false, enableGoToHome = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginHardBlocker, currentLanguageTitles } = useSelector(
    (state) => state.languageReducer.data
  );

  return (
    <ReactModal
      style={{
        ...customStyles,
        overlay: {
          zIndex: 500000000,
        },
      }}
      className="flex p-[5%] justify-center items-center h-[100vh] bg-black bg-opacity-50 !z-[500000000] relative"
      isOpen={loginHardBlocker}
    >
      <div className="max-w-[98%] bg-black rounded-lg flex flex-col justify-center items-center py-10 px-20 z-[500000000]">
        <img src={logo} alt="logo" className="mb-3" />
        <p className="text-yellowish text-nowrap text-xl text-center font-bold mb-6">
          {currentLanguageTitles?.[0]?.login_meta?.login_to_cotinue_lbl ?? "Login to Continue!"}
        </p>
        <div className="w-fit flex justify-between gap-5 md:gap-16 px-[2%]">
          <button
            onClick={() => {
              dispatch(setLoginHardBlocker(false));
              if (enableGoToHome) {
                navigate("/home");
              } else if (enableBack) {
                navigate(-1);
              }
            }}
            className="text-white px-8 py-2 md:py-4 border border-white rounded-lg font-semibold text-sm md:text-lg hover:bg-white hover:text-black text-nowrap"
          >
            {enableGoToHome
              ? currentLanguageTitles?.[0]?.login_meta?.go_back_home_btn ?? "Go Back Home"
              : currentLanguageTitles?.[0]?.login_meta?.cancel_btn ?? "Cancel"}
          </button>

          <button
            onClick={() => {
              dispatch(setLoginHardBlocker(false));
              navigate("/login");
            }}
            className="text-white px-8 py-2 md:py-4 border border-white rounded-lg font-semibold text-sm md:text-lg hover:bg-white hover:text-black text-nowrap"
          >
            {currentLanguageTitles?.[0]?.login_meta?.loginId_lbl ?? "Log In"}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default LoginHardBlocker;
