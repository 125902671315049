import * as actionTypes from "../ActionTypes";

export const curriculumReadAction = (payload) => ({
    type: actionTypes.CURRICULUM_READ_REQUEST,
    payload,
});

export const curriculumHomeReadAction = (payload) => ({
    type: actionTypes.CURRICULUM_HOME_READ_REQUEST,
    payload,
});