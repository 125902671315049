import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, EffectCoverflow } from "swiper/modules";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { GoMute, GoUnmute } from "react-icons/go";
import Hls from "hls.js";

const CoverEffectCarousel = ({
  data,
  title,
  height,
  width,
  className,
  onItemChange,
  backTo,
  seeAllRoute = null,
  copySwiper = null,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef();
  const { isMobile } = useSelector((state) => state.common);
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const [swiper, setSwiper] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showAnimation, setShowAnimation] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [videoPlayed, setVideoPlayed] = useState(false);
  const [videoMuted, setVideoMuted] = useState(true);

  const handleNavigate = (item, index) => {
    if (item?.type === "videos") {
      navigate(`/videoInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (item?.type === "category") {
      if (seeAllRoute) {
        navigate(`/${seeAllRoute}`, { state: { videoObjId: item?._id } });
      } else {
        navigate(`/categoryInfo/${item._id}`, { state: { _id: item?._id } });
      }
    } else if (item?.type === "curriculum") {
      navigate(`/curriculum-details/${item._id}`, {
        state: { _id: item?._id },
      });
    } else if (item?.type === "testimonial") {
      navigate(`/testimonialInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (item?.type === "promise") {
      navigate("/video-player/", {
        state: { url: item?.video, backTo: backTo, returnTo: location?.pathname },
      });
    } else {
      navigate(`/lesson-details/${item._id}/${index}`, {
        state: { _id: item?._id },
      });
    }
  };

  const handleMouseEnter = () => {
    console.log("handleMouseEnter");
    if (swiper) {
      console.log("handleMouseEnter swiper", swiper);
      swiper?.autoplay?.stop();
    }

    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    console.log("handleMouseLeave");
    if (swiper) {
      console.log("handleMouseLeave swiper", swiper);
      swiper?.autoplay?.start();
    }
    setIsHovered(false);
    setVideoPlayed(false);
  };

  useEffect(() => {
    let timer = null;
    if (isHovered) {
      timer = setTimeout(() => {
        setVideoPlayed(true);
      }, 1500);
    }

    return () => {
      if (timer) clearTimeout(timer);
      setVideoPlayed(false);
    };
  }, [isHovered]);

  const handleSlideChange = (swiper) => {
    console.log(swiper, "swiper");
    setShowAnimation(true);
    setActiveIndex(swiper.realIndex);
    if (onItemChange) onItemChange(swiper.realIndex, swiper);
  };

  useEffect(() => {
    if (showAnimation) {
      setTimeout(() => {
        setShowAnimation(false);
      }, 600);
    }
  }, [showAnimation]);

  return (
    <div className={`md:px-6 pt-[3%] relative ${className ?? ""}`}>
      {title &&
        (seeAllRoute ? (
          <Link
            to={seeAllRoute}
            className="px-6 md:px-0 md:mb-6 text-2xl font-semibold text-white hover:underline"
          >
            {title}
          </Link>
        ) : (
          <p className="px-6 md:px-0 md:mb-6 text-2xl font-semibold text-white">{title}</p>
        ))}
      <div className="w-full" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Swiper
          onInit={(ev) => {
            setSwiper(ev);
            if (copySwiper) copySwiper(ev);
          }}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 45,
            stretch: 900,
            depth: width * 0.105,
            modifier: 1,
            slideShadows: false,
          }}
          loop={true}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          className="flex justify-center"
          onSlideChange={handleSlideChange}
          breakpoints={{
            640: {
              coverflowEffect: {
                stretch: width * 0.74,
                depth: width * 0.105,
              },
            },
            1024: {
              stretch: width * 0.78,
              depth: width * 0.105,
            },
          }}
        >
          {data?.map((row, index) => (
            <SwiperSlide key={index}>
              <div className="flex justify-center py-3 px-12 md:p-12">
                <div
                  className="w-full md:w-[60%] aspect-[16/12] md:aspect-[16/9] bg-cover bg-top bg-no-repeat rounded-xl relative overflow-hidden hover:scale-110 transition-all duration-500 bg-yellowish/20"
                  style={{
                    backgroundImage: `url('${row?.image}')`,
                  }}
                  onClick={() => {
                    handleNavigate(row, index);
                  }}
                >
                  <div
                    className={`absolute w-full h-full bg-gradient-to-r from-black ${
                      activeIndex === index ? "to-transparent opacity-50" : "to-black opacity-80"
                    } `}
                  ></div>
                  {activeIndex === index && (
                    <>
                      {row?.type === "videos" && row?.video && videoPlayed && (
                        <>
                          <ReactPlayer
                            ref={videoRef}
                            url={row?.video}
                            className={`custom-player absolute top-0 left-0 w-full h-full aspect-video bg-blackishV2 object-cover object-top`}
                            width={"100%"}
                            height={"100%"}
                            playing={true}
                            muted={videoMuted}
                            config={{
                              file: {
                                forceHLS: row?.video?.includes(".m3u8"),
                                hlsVersion: "latest",
                                hls: (url) => {
                                  const hls = new Hls();
                                  hls.loadSource(url);
                                  return hls;
                                },
                              },
                            }}
                          />
                          <button
                            className={`bg-transparent border-none absolute text-xl text-white z-50 right-7 bottom-7`}
                            onClick={() => {
                              if (videoRef.current) {
                                setVideoMuted((prev) => !prev);
                              }
                            }}
                          >
                            {videoMuted ? <GoMute /> : <GoUnmute />}
                          </button>
                        </>
                      )}
                      <div
                        className={`${
                          showAnimation ? "btn-slide3" : ""
                        } absolute bottom-[7%] lg:w-[70%] md:w-[80%] px-[2%]`}
                      >
                        <p className="text-white lg:text-4xl md:text-base text-left font-bold drop-shadow-xl px-5 md:mb-4">
                          {row?.name}
                        </p>
                        <p className="text-white text-base text-left drop-shadow-xl px-5 text-opacity-80">
                          {row?.subtitle?.length <= (isMobile ? 25 : 110)
                            ? row?.subtitle
                            : row?.subtitle?.substring(0, isMobile ? 25 : 108) + " .."}
                        </p>
                        <div className="mx-5 mt-4 w-full">
                          <button className="border border-yellowish hover:bg-yellowish hover:text-black px-[12%] lg:py-[10px] md:py-[8px] py-1 rounded-md text-yellowish font-semibold lg:text-base md:text-xs mb-1 z-[100] relative">
                            {currentLanguageTitles?.[0]?.home_meta?.watchnow ?? "Watch Now"}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="absolute top-[50%] z-10 opacity-20 hover:opacity-95 cursor-pointer"
          onClick={() => swiper.slidePrev()}
        >
          <FaCaretLeft size={50} color="white" />
        </div>
        <div
          className="absolute top-[50%] right-5 z-10 opacity-20 hover:opacity-95 cursor-pointer"
          onClick={() => swiper.slideNext()}
        >
          <FaCaretRight size={50} color="white" />
        </div>
      </div>
    </div>
  );
};

export default CoverEffectCarousel;
