import * as actionTypes from "../ActionTypes";
import { put, call, takeLatest } from "redux-saga/effects";
import * as api from "./CurriculumAPI";
import { actionUpdateGlobalLoaderSagaAction } from "../GlobalLoader/GlobalLoaderAction";

function* curriculumReadRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.readCurriculum, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* curriculumHomeReadRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.readHomeCurriculum, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

export default function* CurriculumWatcherSaga() {
    yield takeLatest(
        actionTypes.CURRICULUM_READ_REQUEST,
        curriculumReadRequestSaga
    );

    yield takeLatest(
        actionTypes.CURRICULUM_HOME_READ_REQUEST,
        curriculumHomeReadRequestSaga
    );
}