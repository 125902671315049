import * as actionTypes from "../ActionTypes";

const INITIAL = {
  loading: false,
  showNavigationAlert: false,
};

const GlobalLoaderReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case actionTypes.APP_LOADING:
      return { ...state, loading: action.payload };
    case actionTypes.SHOW_NAVIGATION_ALERT:
      return { ...state, showNavigationAlert: action.payload };
    default:
      return state;
  }
};

export default GlobalLoaderReducer;
