import * as actionTypes from "../ActionTypes";
import { put, call, takeLatest } from "redux-saga/effects";
import * as api from "./TestimonialAPI";
import { actionUpdateGlobalLoaderSagaAction } from "../GlobalLoader/GlobalLoaderAction";

function* testimonialReadRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readTestimonial, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* testimonyOFTheDayReadRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readTestimonyOfTheDay, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* testimonialSaveRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveTestimonial, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* testimonialCategorySaveRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveTestimonialCategory, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* testimonialCategoryReadRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readTestimonialCategory, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* testimonialTrendingReadRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readTestimonialTrending, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* testimonialLikedReadRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readTestimonialLiked, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* testimonialByCategoryReadRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readTestimonialByCategory, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* testimonialInReviewReadRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readTestimonialInReview, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* testimonialApprovedReadRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readTestimoniaApproved, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

export default function* TestimonialWatcherSaga() {
  yield takeLatest(
    actionTypes.TESTIMONIAL_READ_REQUEST,
    testimonialReadRequestSaga
  );

  yield takeLatest(
    actionTypes.TESTIMONY_OF_THE_DAY_READ_REQUEST,
    testimonyOFTheDayReadRequestSaga
  );
  yield takeLatest(
    actionTypes.TESTIMONIAL_SAVE_REQUEST,
    testimonialSaveRequestSaga
  );
  yield takeLatest(
    actionTypes.TESTIMONIAL_CATEGORY_SAVE_REQUEST,
    testimonialCategorySaveRequestSaga
  );
  yield takeLatest(
    actionTypes.TESTIMONIAL_CATEGORY_READ_REQUEST,
    testimonialCategoryReadRequestSaga
  );
  yield takeLatest(
    actionTypes.TESTIMONIAL_READ_TRENDING_REQUEST,
    testimonialTrendingReadRequestSaga
  );
  yield takeLatest(
    actionTypes.TESTIMONIAL_READ_LIKED_REQUEST,
    testimonialLikedReadRequestSaga
  );
  yield takeLatest(
    actionTypes.TESTIMONIAL_READ_BY_CATEGORY_REQUEST,
    testimonialByCategoryReadRequestSaga
  );
  yield takeLatest(
    actionTypes.TESTIMONIAL_READ_INREVIEW_REQUEST,
    testimonialInReviewReadRequestSaga
  );
  yield takeLatest(
    actionTypes.TESTIMONIAL_READ_APPROVED_REQUEST,
    testimonialApprovedReadRequestSaga
  );
}
