import React, { useLayoutEffect, useState } from "react";
import { navIconArray, logo, navActiveCss, navHoverCss } from "../../constants/NavbarConstants";
import { NavLink, Link, ScrollRestoration, useLocation } from "react-router-dom";
import NavigationConfirmModal from "./NavigationConfirmModal";
import { useSelector } from "react-redux";

const Navbar = ({ active, blocker = null }) => {
  const location = useLocation();

  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);
  const [showName, setShowName] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [hovered, setHovered] = useState(true);
  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const onMouseOver = () => {
    setShowName(true);
  };

  const onMouseOut = () => {
    setShowName(false);
  };

  const toggleMenu = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <>
      <NavigationConfirmModal blocker={blocker} />
      <div className="md:flex hidden p-10 fixed bg-black h-[100vh] flex-col justify-center z-50">
        <Link to="/" className={`${showName ? "z-[100000]" : "z-[100]"}`}>
          <img
            className="absolute top-4 left-5 cursor-pointer w-[60px]"
            src={logo}
            alt=""
          />
        </Link>
        <ul
          className="navbar-main relative flex flex-col h-full justify-center items-center"
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        >
          {navIconArray.map((item, i) => (
            <li className={`cursor-pointer py-5 z-10`} key={"navitem" + i}>
              <div className="relative z-10">
                <img
                  className="nav-icons cursor-pointer"
                  src={active === item.title ? item.hover : item.icon}
                  alt=""
                />
              </div>
            </li>
          ))}
          {showName && (
            <li className="nav-hover-bg absolute h-[100vh] w-[300px] left-0 flex flex-col justify-center">
              {navIconArray.map((item, i) => (
                <NavLink
                  className="nav-container flex"
                  key={i}
                  to={item.route}
                  onMouseEnter={() => handleMouseEnter(i)}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    className="nav-icons cursor-pointer"
                    src={hoveredItem === i ? item.hover : item.icon}
                    alt=""
                  />
                  <p
                    key={i}
                    className="nav-titles top-0 left-10 py-5 pl-4 z-50 cursor-pointer font-semibold text-white opacity-50 "
                  >
                    {currentLanguageTitles?.[0]?.menus?.[item.key] ?? item.title}
                  </p>
                </NavLink>
              ))}
            </li>
          )}
        </ul>
      </div>
      <nav className="MOBILE-NAV md:hidden flex items-center justify-between bg-blackish shadow-lg sticky top-0 p-4 z-[10000] w-full">
        <div className="flex items-center justify-between w-full">
          <div
            className={`hamburger-icon cursor-pointer ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <div className="hamburger-line"></div>
            <div className="hamburger-line"></div>
            <div className="hamburger-line"></div>
          </div>

          <Link className="text-white ml-4 font-bold" to={"/login"}>
            <img className="h-12 w-12 object-contain cursor-pointer" src={logo} alt="" />
          </Link>
        </div>

        <div
          onMouseOver={() => setHovered(true)}
          className={`mobile-menu ${menuOpen ? "open" : ""}`}
        >
          <p className="text-lg text-greyish mt-4 mb-4 text-center">Menus</p>
          <ul className="ICON-DIV flex flex-col items-center gap-8">
            {navIconArray.map((item, i) => (
              <NavLink
                key={i}
                to={item.route}
                className={({ isActive, isPending }) =>
                  isPending ? "" : isActive ? `${navActiveCss} h-10 ` : `${navHoverCss} h-10 `
                }
              >
                <li className="relative cursor-pointer p-2 flex flex-col justify-center">
                  <img src={item.icon} alt="menu icon" className="h-8 object-contain" />
                  <p
                    key={i}
                    className="nav-titles z-50 cursor-pointer font-semibold text-white opacity-50 "
                  >
                    {currentLanguageTitles?.[0]?.menus?.[item.key] ?? item.title}
                  </p>
                </li>
              </NavLink>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
