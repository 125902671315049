import Axios from "../../utils/axios";

//Get Country data
export const getCategories = (payload) => {
  const URL = "/categories/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCategoriesTestimony = (payload) => {
  const URL = "/categories/testimony/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateCategoriesTestimony = (payload) => {
  const URL = "/categories/testimony/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const deleteCategoriesTestimony = (payload) => {
  const URL = "/categories/testimony/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const deleteTestimony = (payload) => {
  const URL = "/testimony/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const readLayout = (payload) => {
  const URL = "/layout/read";
  return Axios.post(URL, payload).then((res) => {
      return res.data;
  });
};
