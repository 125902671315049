import { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { setMobileView } from "./store/Common/CommonAction";
import PrivacyPolicy from "./components/common/PrivacyPolicy";
import { languageCurrentAction, languagePreferredAction } from "./store/Home/HomeAction";
import Contact from "./components/common/Contact";
import Media from "./components/common/Media";
import Devotions from "./screens/devotions/Devotions";
import CertificateDownload from "./screens/certificate/CertificateDownload";
import PageLoader from "./components/common/PageLoader";

const HomePage = lazy(() => import("./screens/home/HomePage"));
const LoginPage = lazy(() => import("./screens/login/LoginPage"));
const Curriculum = lazy(() => import("./screens/curriculum/Curriculum"));
const MyFavourites = lazy(() => import("./screens/myFavourites/MyFavourites"));
const Givings = lazy(() => import("./screens/givings/Givings"));
const Testimonials = lazy(() => import("./screens/testimonials/Testimonials"));
const VideoInfo = lazy(() => import("./components/common/VideoInfo"));
const SeeAll = lazy(() => import("./components/common/SeeAll"));
const MyProfile = lazy(() => import("./components/profileMenu/MyProfile"));
const MyDownloads = lazy(() => import("./components/profileMenu/MyDownloads"));
const GivingTwo = lazy(() => import("./components/givings/GivingTwo"));
const GivingPastList = lazy(() => import("./components/givings/GivingPastList"));
const About = lazy(() => import("./components/profileMenu/About"));
const LessonDetails = lazy(() => import("./components/curriculum/LessonDetails"));
const VideoPlayerContainer = lazy(() => import("./components/VideoPlayer/VideoPlayerContainer"));
const CategoryInfo = lazy(() => import("./components/common/CategoryInfo"));
const TestimonialInfo = lazy(() => import("./components/common/TestimonialInfo"));
const CurriculumDetailsV2 = lazy(() => import("./components/curriculum/CurriculumDetailsV2"));
const SessionExpired = lazy(() => import("./components/common/SessionExpired"));
const HelpAndSupport = lazy(() => import("./screens/support/HelpAndSupport"));

function App() {
  const dispatch = useDispatch();
  const { languageHeadings } = useSelector((state) => state.languageReducer.data);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 720) {
        dispatch(setMobileView(true));
      } else {
        dispatch(setMobileView(false));
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    const language_preferred = localStorage.getItem("language_preferred");
    if (language_preferred) {
      const currentLanguage = languageHeadings.filter(
        (item) => item.language_code === language_preferred
      );
      dispatch(languageCurrentAction(currentLanguage));
      dispatch(languagePreferredAction(language_preferred));
    } else {
      localStorage.setItem("language_preferred", "en");
      const currentLanguage = languageHeadings.filter((item) => item.language_code === "en");
      dispatch(languageCurrentAction(currentLanguage));
      dispatch(languagePreferredAction(language_preferred));
    }

    return window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="web-app">
      <Suspense fallback={<PageLoader />}>
        <RouterProvider
          router={createBrowserRouter(
            createRoutesFromElements(
              <>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/logout" element={<SessionExpired />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/devotions" element={<Devotions />} />
                <Route path="/devotions/:id" element={<Devotions />} />
                <Route path="/curriculum" element={<Curriculum />} />
                <Route path="/curriculum-details/:id" element={<CurriculumDetailsV2 />} />
                <Route path="/lesson-details/:id/:index" element={<LessonDetails />} />
                <Route path="/favourites" element={<MyFavourites />} />
                <Route path="/givings" element={<Givings />} />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/videoInfo/:id" element={<VideoInfo />} />
                <Route path="/categoryInfo/:id" element={<CategoryInfo />} />
                <Route path="/testimonialInfo/:id" element={<TestimonialInfo />} />
                <Route path="/seeAll" element={<SeeAll />} />
                <Route path="/profile" element={<MyProfile />} />
                <Route path="/downloads" element={<MyDownloads />} />
                <Route path="/givings/comp" element={<GivingTwo />} />
                <Route path="/givings/past-list" element={<GivingPastList />} />
                <Route path="/about" element={<About />} />
                <Route path="/video-player" element={<VideoPlayerContainer />} />
                <Route path="/support" element={<HelpAndSupport />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/media" element={<Media />} />
                <Route path="/certificate/:id" element={<CertificateDownload />} />
              </>
            )
          )}
        />
        <ToastContainer />
      </Suspense>
    </div>
  );
}

export default App;
